import * as React from 'react'
import SidebarHighlight from './sidebar/sidebarHighlight'
import SidebarPrice from './sidebar/sidebarPrice'

const Sidebar = () => {
    return (
        <div className='flex flex-col space-y-6'>
            <div>
                <SidebarPrice />
            </div>
            <div>
                <h1 className='text-skin-primary font-medium'>RELATED STORIES</h1>
                <SidebarHighlight
                    acategory='CURRENCY'
                    atitle='Me non paenitet nullum festiviorem excogitasse ad hoc...'
                    adate='Mar 2nd'
                    ggpoint='30'
                    alink='#'
                />
                <SidebarHighlight
                    acategory='BLOCKCHAIN'
                    atitle='Vivamus sagittis lacus vel augue laoreet rutrum faucibus. Curabitur est gravida et libero vitae dictum...'
                    adate='Mar 2nd'
                    ggpoint='30'
                    alink='#'
                />
                <SidebarHighlight
                    acategory='Currency'
                    atitle='Plura mihi bona sunt, inclinet, amari petere vellent. Cras mattis iudicium purus sit amet fermentum. Integer legentibus erat a ante historiarum dapibus...'
                    adate='Mar 2nd'
                    ggpoint='30'
                    alink='#'
                />
                <SidebarHighlight
                    acategory='Currency'
                    atitle='Curabitur blandit tempus ardua ridiculus sed magna. Mercedem aut nummos unde unde extricat, amaras...'
                    adate='Mar 2nd'
                    ggpoint='30'
                    alink='#'
                />
                <SidebarHighlight
                    acategory='Currency'
                    atitle='Me non paenitet nullum festiviorem excogitasse ad hoc.'
                    adate='Mar 2nd'
                    ggpoint='30'
                    alink='#'
                />
                <SidebarHighlight
                    acategory='Currency'
                    atitle='Me non paenitet nullum festiviorem excogitasse ad hoc.'
                    adate='Mar 2nd'
                    ggpoint='30'
                    alink='#'
                />
            </div>
        </div>
        
    )
}

export default Sidebar