import * as React from 'react'

// alink = link to the article detail
// ggpoint = points for reading this article

const SidebarHighlight = ({atitle, acategory, adate, ggpoint, alink}) => {
    return (
        <div className='mb-6'>
            <div className='p-2 border-b'>
                <h1 className='mb-2 text-article'>{ atitle }</h1>
                <div className='text-sm text-skin-muted flex flex-row'>
                    <div className='P-2 flex-1 border-r'>{ acategory } | {adate}</div>
                    <div className='P-2 flex-1 text-right'>Point: { ggpoint }</div>
                </div>
            </div>
        </div>
    )
}

export default SidebarHighlight