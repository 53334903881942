import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Sidebar from '../../components/articles/sidebar'
import SocialSharing from '../../components/sharing'
import { AllArticle } from '../../components/articles/index-articles'
import { graphql } from 'gatsby'

const ArticleIndex = ({ data }) => {
    console.log(data);
    return (
        <>
            <div className="container mx-auto grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 md:flex-row mt-6 md:space-x-12">
              <AllArticle data={data.gogobackend.all}/>
            </div>

        </>
        
    )
}

export const queryPrimary = graphql`
query {
  gogobackend {
      primary: news_articles_content(
        status: "Published"
        page_location: "primary"
        limit: 1
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          image,
          created_at
    }
      secondary: news_articles_content(
        status: "Published"
        page_location: "secondary"
        limit: 2
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          image,
          created_at
    }
      all: news_articles_content(
        status: "Published"
        page_location: "primary"
        limit: 20
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          image,
          created_at
      }
    }
  }
`
export default ArticleIndex